import { z } from 'zod';
import { productDocumentSchema } from './products';

export const reviewDocumentSchema = z.object({
  id: z.string().optional(),
  comment: z.string().optional(),
  //  MX Locker ratings
  // fast_shipper: z.boolean().default(false),
  // as_described: z.boolean().default(false),
  // quick_replies: z.boolean().default(false),

  // Gear Focus ratings
  communication: z
    .literal('Bad')
    .or(z.literal('Good'))
    .or(z.literal('Excellent')),

  delivery_time: z
    .literal('Bad')
    .or(z.literal('Good'))
    .or(z.literal('Excellent')),

  is_anonymous: z.boolean().default(false),
  rating: z.number().min(1).max(5).default(5),
  created_at: z.number().optional(),
  order_id: z.string(),
  uid: z.string(),
  reviewer_id: z.string(),
  products: z.array(productDocumentSchema),
});

export type ReviewDocument = z.infer<typeof reviewDocumentSchema>;
