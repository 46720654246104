import { ProductDocument } from '@util/types/firestore/products';
import { onSnapshot } from 'firebase/firestore';
import { useMemo, useState, useEffect } from 'react';
import { getAuctionsQuery } from '@util/firestore/products';

const useRealTimeAuctions = () => {
  const [realTimeProducts, setProducts] = useState<ProductDocument[]>([]);
  const productsQuery = useMemo(() => getAuctionsQuery(), []);
  useEffect(() => {
    const unsubscribe = onSnapshot(productsQuery, (snapshot) => {
      const realTimeData = snapshot.docs.map((doc) => doc.data());
      setProducts(realTimeData);
    });
    return () => {
      unsubscribe();
    };
  }, [productsQuery]);

  return {
    realTimeProducts,
  };
};

export default useRealTimeAuctions;
