import { StarIcon } from '@c/icons';
import { GoogleIcon } from '@c/icons';
import SafeImage from './SafeImage';

interface ReviewProps {
  img: string;
  name: string;
  content: string;
  googleReviewLink?: string;
}
const Review = ({ img, name, content, googleReviewLink }: ReviewProps) => {
  return (
    <div className="flex h-fit w-[100vw] flex-col justify-center px-[4rem] sm:h-[40rem] md:w-[50rem] lg:w-[80rem] lg:px-[12.8rem] xl:w-[120rem]">
      <div className="flex flex-col flex-wrap items-center gap-[0.8rem] sm:flex-row sm:flex-nowrap">
        <div className="mr-[1.6rem] h-[4.8rem] w-[4.8rem] overflow-clip rounded-full bg-gray-200">
          <SafeImage src={img} alt="Not Found" />
        </div>
        <div className="flex flex-col gap-[0.8rem]">
          <div className="font-semibold">{name}</div>
          {/* stars */}
          <div className="flex items-center">
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
          </div>
        </div>
        {/* view on google, far right */}
        <div className="grow"></div>
        {googleReviewLink && (
          <div className="flex items-center gap-[0.8rem] font-semibold text-brand-secondary">
            <GoogleIcon />
            View on Google
          </div>
        )}
      </div>
      {/* content */}
      <div className="mt-[3.2rem]">
        <div className="italic text-gray-500">{content}</div>
      </div>
    </div>
  );
};

export default Review;
