import LoadingSpinner from '@c/LoadingSpinner';
import CategorySkeleton from '@c/skeletons/CategorySkeleton';
import useLoading from '@util/hooks/useLoading';
import NavbarFooterLayout from './NavbarFooterLayout';
import PageLayout from './PageLayout';

type Props = {
  children: React.ReactNode;
};

function getLoadingIndicator(v?: LoadingIndicatorVariants) {
  if (v === 'categorySkeleton') return CategorySkeleton;
  return LoadingSpinner;
}

type LoadingIndicatorVariants = 'spinner' | 'categorySkeleton';

const isString = (val: any) => typeof val === 'string' || val instanceof String;

export const DefaultLayout = ({ children }: Props) => {
  const { loading, nextUrl } = useLoading();
  let safeIsLoading = loading;
  // there's some weird timing bug going on with the useLoading hook
  // and client side navigation, this seems to solve it
  if (loading) {
    if (typeof window !== 'undefined') {
      const currentUrl = window.location.pathname;
      safeIsLoading = nextUrl !== currentUrl;
    }
  }
  const loadingIndicatorVariant =
    isString(nextUrl) && nextUrl.includes('/c/')
      ? 'categorySkeleton'
      : 'spinner';
  const Loader = getLoadingIndicator(loadingIndicatorVariant);
  return (
    <NavbarFooterLayout>
      <PageLayout>{safeIsLoading ? <Loader /> : children}</PageLayout>
    </NavbarFooterLayout>
  );
};
