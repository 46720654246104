import { useState, useEffect } from 'react';
import { LogoutIcon } from '@c/icons';
import Accordion from '@ui/Accordion';
import BaseDrawer from '@c/drawers/BaseDrawer';
import Button from '@ui/Button';
import NavLink from '@ui/Link/NavLink';
import { useAuth } from 'context/AuthContext';
import { BRANDS } from '@util/maps/brands';
import Link from 'next/link';
import { useQuery } from '@tanstack/react-query';
import { Brand } from '@util/types/brands';
import { getBrands } from 'pages/api/brands';
import { getBrandUrl } from '@util/urlHelpers';
import dynamic from 'next/dynamic';
import { getCategories, CategoryDocument } from '@util/firestore/categories'

const DashboardSideMenu = dynamic(
  () => import('@c/dashboard/DashboardSideMenu'),
  { ssr: false }
);

interface MenuDrawer {
  show: boolean;
  dismiss: () => void;
}

const MenuDrawer = ({ show, dismiss }: MenuDrawer) => {
  const { userDoc, logout } = useAuth();

  const [categories, setCategories] = useState<CategoryDocument[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const allCategories = await getCategories(1);
        setCategories(allCategories);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const { data: brands } = useQuery(['brands'], getBrands, {
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const top_brands = brands?.filter((brand) =>
    BRANDS.includes(brand.name as Brand)
  );

  return (
    <div className="min-w-[100vw]">
      <div className="top-[8rem]">
        <BaseDrawer
          show={show}
          dismiss={() => {
            dismiss();
          }}
        >
          <div className=" h-full w-full  ">
            {userDoc ? (
              <DashboardSideMenu menuItemClicked={() => dismiss()} />
            ) : (
              <div className="h-full w-full overflow-scroll pb-[15rem] pr-[1rem]">
                <div className="mx-[0.8rem] my-[1.6rem] flex flex-col justify-between gap-[1.6rem] p-[0.8rem] text-sm font-semibold">
                  <Button
                    text="Sell your gear"
                    href="/signup?redirect=/become-a-seller"
                    height="small"
                    width="fluid"
                  />
                </div>
                <div className="flex items-center px-[1rem] font-semibold">
                  <span className="uppercase">Shop</span>
                </div>
                <div className="pl-[1.6rem]">
                  <Accordion
                    title={'Categories'}
                    highlightHeaderOnOpen
                    noBoldTitle
                  >
                    <div className="flex flex-col gap-[0.8rem] px-[1.6rem]">
                      {categories.map((category: CategoryDocument) => (
                        <NavLink
                          href={`/c/${category.slug}`}
                          onClick={() => {
                            dismiss();
                          }}
                          key={category.id}
                        >
                          {category.name}
                        </NavLink>
                      ))}
                    </div>
                  </Accordion>
                  <Accordion title={'Brands'} noBoldTitle>
                    <div className="flex flex-col gap-[0.8rem] px-[1.6rem]">
                      {top_brands?.map((brand) => (
                        <Link
                          className={`$ font-medium transition-colors hover:text-brand-primary`}
                          href={getBrandUrl(brand)}
                          onClick={() => {
                            dismiss();
                          }}
                          key={brand.name}
                        >
                          {brand.name}
                        </Link>
                      ))}
                    </div>
                  </Accordion>
                </div>

                <div className="mt-[1.8rem] flex items-center px-[1rem] font-semibold">
                  <span className="uppercase">Account</span>
                </div>
                <div className="flex flex-col gap-[1.6rem] px-[2.4rem] py-[1.6rem]">
                  <Link href="login">Login</Link>
                  <Link href="signup">Sign up</Link>
                </div>

                <div className="mt-[1.8rem] flex items-center px-[1rem] font-semibold">
                  <span className="uppercase">About Us</span>
                </div>
                <div className="flex flex-col gap-[1.6rem] px-[2.4rem] py-[1.6rem]">
                  <Link
                    href="faq"
                    onClick={() => {
                      dismiss();
                    }}
                  >
                    FAQ
                  </Link>
                  <Link
                    onClick={() => {
                      dismiss();
                    }}
                    href="sellyourgear#howitworks"
                  >
                    How it works
                  </Link>
                </div>

                {userDoc && (
                  <div className="flex flex-col items-start">
                    <Button
                      type="text"
                      text="Sign out"
                      href="/"
                      leadingIcon={<LogoutIcon />}
                      onClick={() => {
                        logout();
                        dismiss();
                      }}
                      width="small"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </BaseDrawer>
      </div>
    </div>
  );
};

export default MenuDrawer;
