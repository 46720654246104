import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

type UseLoadingState = {
  loading: boolean;
  nextUrl: string;
};

const useLoading = () => {
  const router = useRouter();
  const [loadingState, setLoadingState] = useState<UseLoadingState>({
    loading: false,
    nextUrl: '/',
  });
  useEffect(() => {
    const handleStart = (url: string) => {
      if (url !== router.asPath) {
        setLoadingState({ loading: true, nextUrl: url });
      }
    };
    const handleComplete = (url: string) => {
      setLoadingState({ loading: false, nextUrl: url });
    };
    const handleError = (url: string) => {
      setLoadingState({ loading: false, nextUrl: url });
    };
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleError);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleError);
    };
  });

  return loadingState;
};

export default useLoading;
