import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import dynamic from 'next/dynamic';

const Typewriter = dynamic(() => import('typewriter-effect'), {
  ssr: false,
});

const HomepageHeader = () => {
  return (
    <div className="h-[34rem] w-full select-none bg-brand-black transition-colors sm:h-[64rem]">
      <div className="relative m-auto h-[34rem] max-w-default sm:h-[64rem]">
        {/* text and buttons and stuff */}
        <div className="absolute z-10 mt-[6rem] max-w-[48rem] text-center text-[3.2rem] font-semibold leading-[4.16rem] text-brand-white sm:mt-[16.6rem] sm:max-w-[69rem] sm:pl-default-padding-x sm:text-left sm:text-header1 sm:leading-[6.44rem]">
          <p>A community-driven marketplace for</p>
          <div className="min-h-[4.16rem] bg-gradient-orange bg-clip-text text-transparent sm:min-h-[6.44rem]">
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString('creators.')
                  .pauseFor(1000)
                  .deleteAll()
                  .typeString('photographers.')
                  .pauseFor(1000)
                  .deleteAll()
                  .typeString('filmmakers.')
                  .pauseFor(1000)
                  .deleteAll()
                  .typeString('editors.')
                  .pauseFor(1000)
                  .deleteAll()
                  .start();
              }}
              options={{
                loop: true,
              }}
            />
          </div>
          <div className="m-auto w-fit sm:mx-0">
            <div className="mt-[6rem] hidden gap-[2rem] sm:flex">
              <Button
                width="fixed"
                type="primary"
                text="New Arrivals"
                title="Shop Newly Listed Used & New Cameras and Lenses"
                href="/new-arrivals"
              />
              <Button
                width="fixed"
                type="tertiary"
                text="On Sale"
                title="Browse All New & Used Camera Gear On Sale"
                href="/onsale"
              />
            </div>
            <div className="mt-[4.5rem] sm:hidden">
              <Button type="primary" text="Shop Now" href="/new-arrivals" title="Shop Newly Listed Used & New Cameras and Lenses" />
            </div>
          </div>
        </div>
        {/* dWeb bg image */}
        <SafeImage
          className="absolute right-0 top-0 hidden object-cover sm:block"
          alt={'homepage header'}
          src={'https://gearfocus.b-cdn.net/public/dWeb.webp'}
          height={640}
          width={1014}
          quality={100}
          priority
        />
        {/* mobile bg image */}
        <SafeImage
          className="absolute right-0 top-0 object-cover brightness-75 sm:hidden"
          alt={'homepage header'}
          src={'https://gearfocus.b-cdn.net/public/mWeb.webp'}
          fill
          quality={100}
          priority
        />
      </div>
    </div>
  );
};

export default HomepageHeader;
