import { useState } from 'react';
import Navbar from '../Navbar';
import MenuDrawer from '@c/drawers/MenuDrawer';
import dynamic from 'next/dynamic';

const Footer = dynamic(() => import('@c/Footer'), { ssr: false });

type Props = {
  children: React.ReactNode;
};

export default function NavbarFooterLayout({ children }: Props) {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <Navbar
        showCloseButton={showDrawer}
        onActionButtonClicked={() => {
          setShowDrawer(!showDrawer);
        }}
      />
      <div className="min-h-screen">{children}</div>
      <Footer />
      <MenuDrawer show={showDrawer} dismiss={() => setShowDrawer(false)} />
    </>
  );
}
