import { db } from '@util/firebase';
import { OrderDocument } from '@util/types/firestore/order';
import {
  ReviewDocument,
  reviewDocumentSchema,
} from '@util/types/firestore/review';
import {
  collection,
  CollectionReference,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  getDoc,
} from 'firebase/firestore';
import { getUserByUsername, getUserDocument } from '../users';

const reviewsRef = collection(
  db,
  'reviews'
) as CollectionReference<ReviewDocument>;

export const getReviewByOrderId = async (id: string) => {
  const q = query(reviewsRef, where('order_id', '==', id), limit(1));
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return null;
  }
  const data = reviewDocumentSchema.parse(snapshot.docs?.[0]?.data() ?? null);
  return data;
};

export const submitReview = async (review: ReviewDocument) => {
  if (!review.uid) return null;
  const reviewDoc = await addDoc(reviewsRef, review);
  if (reviewDoc.id) {
    const ordersRef = doc(db, 'orders', review.order_id);
    const docSnap = await getDoc(ordersRef);
    const order = (docSnap.data() as OrderDocument) ?? null;
    if (order) {
      await updateDoc(ordersRef, {
        reviewed_sellers: [...(order.reviewed_sellers ?? []), review.uid],
      });
    }
  }

  return reviewDoc.id;
};

export const getReviewsByUsername = async (username: string) => {
  const user = await getUserByUsername(username);
  if (!user) return [];
  const q = query(reviewsRef, where('uid', '==', user.uid));
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return [];
  }
  const data = snapshot.docs.map((doc) =>
    reviewDocumentSchema.parse(doc.data())
  );
  return data;
};

export const getReviewsByUid = async (uid: string) => {
  const q = query(reviewsRef, where('uid', '==', uid));
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return [];
  }
  const data = snapshot.docs.map((doc) => {
    return doc.data();
  });
  return data;
};

export interface StaticReview {
  id: string;
  img: string;
  name: string;
  content: string;
  googleReviewLink?: string;
}
export const STATIC_REVIEWS: StaticReview[] = [
  {
    id: '1',
    img: '/reviews/1.webp',
    name: 'Corey Hester',
    content:
      'First google review Ive ever been compelled to write. Gear Focus is awesome!! Great selection. Great website. INCREDIBLE customer service!! I had an issue with purchasing a product given my shipping was outside the continental US and had an issue with the checkout process and my credit card. However, Gear Focus and their team IMMEDIATELY got back to me after I reached out (despite it being past 5pm) and promptly fixed the issue with my checkout and shipping address in a professional and customer service friendly manner. I would 100% recommend this company, their products and services, and their team support to anyone. I will absolutely be purchasing from them again soon. 5-star.',
  },
  {
    id: '2',
    img: '/reviews/2.webp',
    name: 'Rich Forsen',
    content:
      'GearFocus has been WONDERFUL to deal with as a seller, and their customer service is human and real. They allow me to post into an audience that I know will have interest in my gear, while not charging the hefty processing / listing fees that eBay typically does. Ive had some great interactions with both the staff at GF and the people Ive sold to through the platform.',
  },
  {
    id: '3',
    img: '/reviews/3.webp',
    name: 'Doug Newton',
    content:
      'I have had incredible service with GearFocus. All of the automated processes have functioned smoothly and the interaction is user-friendly without having to contact support. I have bought and sold (sold more than bought) about 8 items totaling over $15,000. On one occasion I needed their assistance to work through a problem with a customer. They were very personal, very supportive, and quick to help. You can tell they are experts in their field. They are by far my first choice in selling my equipment.',
  },
  {
    id: '4',
    img: '/reviews/4.webp',
    name: 'Lindsey C.',
    content:
      'I’ve sold several pieces of my old camera gear using Gear Focus and love it! Yes, the seller fees are less than eBay, which is great, but for me it’s the fraud protection that keeps me coming back and using their platform. I have so much more piece of mind selling my my stuff through Gear Focus. It’s obvious to me that they truly care about the filmmaking and photography community as well as their users.',
  },
];

export const STATIC_REVIEWS_TWO: StaticReview[] = [
  {
    id: '1',
    img: 'https://lh3.googleusercontent.com/a-/AD5-WCkLUfgOBf0FkeaRNx5bfjgUzpjEujH6q0jgvXVb4w=w40-h40-p-rp-mo-br40',
    name: 'Jack Patmore',
    content:
      'Gear Focus is not only a great marketplace to use for creators to share their unused gear with others, but also a great community to be a part of on YouTube. I have sold multiple things through Gear Focus and their process is easy and simple!',
  },
  {
    id: '2',
    img: 'https://lh3.googleusercontent.com/a/AEdFTp6DIlmHPWmfm251cU-j2Jft5xMvpO-m1duEA_6F=w40-h40-p-rp-mo-br40',
    name: 'Paul Hancock',
    content:
      'I’ve been using Gear Focus for a good while now and it is definitely the place to go for good-quality photographic equipment.',
  },
  {
    id: '3',
    img: 'https://lh3.googleusercontent.com/a-/AD5-WCleVlpab7QEs3XKJ0BSW5zjIvWWjZIPVu4UN1rM=w40-h40-p-rp-mo-br40',
    name: 'Jason Drake',
    content:
      'Had a great experience working with the team at Gear Focus. They were friendly, responsive, and helpful. And the website was easy to use. We got a great used camera and got it quickly to allow us to start using it right away. Really happy with everything!',
  },
];
