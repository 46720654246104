import { useQueryClient } from '@tanstack/react-query';
import { removeUserKey } from '@util/firestore/users';
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';

const useNewGearFocusModal = () => {
  const { userDoc } = useAuth();
  const queryClient = useQueryClient();
  const [showNewGearFocusModal, setShowNewGearFocusModal] = useState(false);
  useEffect(() => {
    if (userDoc?.last_login) {
      setShowNewGearFocusModal(true);
      removeUserKey(userDoc.uid, 'last_login');
      queryClient.invalidateQueries(['user']);
    }
  }, [queryClient, userDoc?.last_login, userDoc?.uid]);

  return [showNewGearFocusModal, setShowNewGearFocusModal] as const;
};

export default useNewGearFocusModal;
