import ImageLink from '@ui/Link/ImageLink';

import newGearImg from '@public/new_gear_arrivals.png';
import dealsAndStealsImg from '@public/deals_and_steals.png';
import vintageCameraImg from '@public/vintage_camera_gear.png';
import staffPicksImg from '@public/staff_picks.png';

export function HomeLinkRow() {
  const links = [
    {
      href: '/new-arrivals',
      title: 'Newly Listed Gear',
      alt: 'Shop Newly Listed Camera Gear',
      img: newGearImg,
    },
    {
      href: '/l/deals-and-steals',
      title: 'Deals & Steals',
      alt: 'Browse Deals And Steals For Used & New Camera Equipment',
      img: dealsAndStealsImg,
    },
    {
      href: '/c/vintage-camera-equipment',
      title: 'Vintage Camera Gear',
      alt: 'Rediscover Vintage Camera Equipment',
      img: vintageCameraImg,
    },
    {
      href: '/l/staff-picks',
      title: 'Staff Picks',
      alt: 'Shop Gear Focus Picks For New Or Used Camera Gear',
      img: staffPicksImg,
    },
  ];

  return (
    <div className="flex flex-wrap justify-center gap-[1.6rem]">
      {links.map((link) => {
        return (
          <ImageLink
            key={link.title}
            variant="tall"
            href={link.href}
            src={link.img}
            alt={link.alt}
            label={link.title}
          />
        );
      })}
    </div>
  );
}
